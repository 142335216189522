'use client';

import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { usePathname } from 'next/navigation';
import React from 'react';

const variants = {
  default: {
    scale: 1.15,
    rotate: 0,
    y: 0,
    maskImage: 'linear-gradient(rgba(0,0,0,1) 50%, rgba(0,0,0,1) 100%)',
  },
  blog: {
    scale: 1.1,
    rotate: -1,
    y: -100,
    maskImage: 'linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)',
  },
  changelog: {
    scale: 1.05,
    rotate: 1,
    y: -100,
    maskImage: 'linear-gradient(rgba(0,0,0,1) 50%, rgba(0,0,0,0.2) 75%)',
  },
  post: {
    scale: 1.15,
    rotate: 0,
    maskImage: 'linear-gradient(rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%)',
  },
};

function getVariant() {
  const pathname = usePathname() || '';
  const fragments = pathname.split('/').filter(Boolean);

  return (fragments.length > 1 && fragments[1] !== 'tag') ||
    ['careers', 'contact'].some((path) => path === fragments[0])
    ? 'post'
    : variants.hasOwnProperty(fragments[0])
      ? fragments[0]
      : 'default';
}

export default function Background() {
  const { scrollY } = useScroll();
  const springConfig = {
    mass: 1,
    stiffness: 200,
    damping: 50,
  };
  const scrollSpring = useSpring(scrollY, springConfig);

  return (
    <motion.div
      className="pointer-events-none absolute inset-x-0 -top-96 -z-10 flex justify-center overflow-hidden lg:-top-72"
      style={{ y: useTransform(scrollSpring, [0, 1000], [0, -100]) }}
    >
      <motion.svg
        width={1300}
        height={1300}
        viewBox="-50 -50 1300 1300"
        className="max-w-screen-md shrink-0 fill-none stroke-slate-6 sm:max-w-screen-lg lg:max-w-none"
        variants={variants}
        animate={getVariant()}
        vectorEffect="non-scaling-stroke"
        transition={{ duration: 1, ease: 'easeOut' }}
      >
        {largeCircles.map((props, index) => (
          <motion.circle
            key={index}
            vectorEffect="non-scaling-stroke"
            transition={{
              delay: 0.1 * 0.5 ** index,
              duration: 15,
              ease: 'linear',
              repeat: Infinity,
              repeatType: 'mirror',
            }}
            {...props}
          />
        ))}
        {linePaths.map(({ from, to }, index) => (
          <React.Fragment key={index}>
            <motion.path
              className="stroke-slate-4"
              d={from}
              initial={{ d: from }}
              animate={{ d: to }}
              opacity={1}
              vectorEffect="non-scaling-stroke"
              transition={{
                delay: 0.1 * 0.5 ** index,
                duration: 20,
                ease: 'linear',
                repeat: Infinity,
                repeatType: 'mirror',
              }}
            />
          </React.Fragment>
        ))}
        {smallCircles.map((props, index) => {
          const sharedProps: any = {
            ...props,
            vectorEffect: 'non-scaling-stroke',
            transition: {
              delay: 0.1 * 0.5 ** index,
              duration: 20,
              ease: 'linear',
              repeat: Infinity,
              repeatType: 'mirror',
            },
          };

          return (
            <React.Fragment key={index}>
              <motion.circle
                strokeWidth={12}
                fill="#0e0e0f"
                stroke="#0e0e0f"
                {...sharedProps}
              />
              <motion.circle className="stroke-slate-5" {...sharedProps} />
            </React.Fragment>
          );
        })}
      </motion.svg>
    </motion.div>
  );
}

// prettier-ignore
const largeCircles = [
  { r: 590, cx: 590, cy: 600, opacity: 1, animate: { x: 15, y: -15, scale: 0.9 } },
  { r: 556, cx: 600, cy: 580, opacity: 0.9, animate: { x: -20, y: 20, scale: 1.02 } },
  { r: 475, cx: 576, cy: 624, opacity: 0.8, animate: { x: -10, y: 10, scale: 0.85 } },
  { r: 405, cx: 585, cy: 575, opacity: 0.7, animate: { x: -20, y: -20, scale: 1.05 } },
];

const smallCircles = [
  { r: 21, cx: 847, cy: 800, animate: { x: -81, y: -65, scale: 1.4 } },
  { r: 32, cx: 1047, cy: 703, animate: { x: 43, y: 7, scale: 1.5 } },
  { r: 20, cx: 890, cy: 259, animate: { x: 55, y: 133, scale: 1.7 } },
  { r: 26, cx: 719, cy: 477, animate: { x: -49, y: -1, scale: 0.9 } },
  { r: 22, cx: 769, cy: 85, animate: { x: -25, y: 119, scale: 3 } },
  { r: 30, cx: 176, cy: 275, animate: { x: 67, y: 1, scale: 0.75 } },
  { r: 26, cx: 109, cy: 615, animate: { x: 47, y: 39, scale: 1.3 } },
  { r: 23, cx: 383, cy: 923, animate: { x: -4, y: -52, scale: 1.4 } },
  { r: 24, cx: 481, cy: 1090, animate: { x: 77, y: -56, scale: 2 } },
  { r: 25, cx: 705, cy: 1084, animate: { x: 94, y: -62, scale: 0.6 } },
];

const linePaths = [
  { from: 'M850.691 780.203L886.575 285.512', to: 'M769 728L943 396' },
  { from: 'M843.001 780.203L770.5 111.5', to: 'M763.5 727L745 202' },
  { from: 'M836.593 782.767L728 501.5', to: 'M758.5 723.5L675 482' },
  { from: 'M828.904 790.456L194.52 291.92', to: 'M755.904 725.456L239 282' },
  { from: 'M826.341 799.427L133.003 622.569', to: 'M753 729.5L161 650' },
  { from: 'M828.904 810.961L405.981 913.488', to: 'M751.5 736.5L387 878' },
  { from: 'M832 815L498.255 1073.69', to: 'M756 744L559 1036' },
  { from: 'M836.593 818.651L714.843 1060.87', to: 'M763.5 751.5L799 1022' },
  { from: 'M866.07 793.019L1024.99 714.843', to: 'M779 729.5L1087 712' },
];
